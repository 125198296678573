// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/$location._c/AppNavigationMenu.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/$location._c/AppNavigationMenu.tsx");
}
// REMIX HMR END

import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import cn from './AppNavigationMenu.module.css';
import { LinkItem } from './LinkItem';
export function AppNavigationMenu({
  locationName,
  minimize = false
}) {
  _s();
  const {
    t
  } = useTranslation();
  const {
    t: tc
  } = useTranslation('consumer');
  return <NavigationMenu.Root orientation="vertical" className={cn.NavigationMenu}>
      <NavigationMenu.List>
        <LinkItem to=".">
          <IconItem icon={<i className="las la-home"></i>} minimize={minimize}>
            {tc('home')}
          </IconItem>
        </LinkItem>
        <LinkItem to="./analytics">
          <IconItem icon={<i className="las la-home"></i>} minimize={minimize}>
            {t('analytics')}
          </IconItem>
        </LinkItem>{' '}
        <LinkItem to="./mural">
          <IconItem icon={<i className="las la-home"></i>} minimize={minimize}>
            {tc('mural')}
          </IconItem>
        </LinkItem>
        <LinkItem to="./users">
          <IconItem icon={<i className="las la-home"></i>} minimize={minimize}>
            {t('user list')}
          </IconItem>
        </LinkItem>
        <LinkItem to="./activities">
          <IconItem icon={<i className="las la-home"></i>} minimize={minimize}>
            {t('activities')}
          </IconItem>
        </LinkItem>
        <LinkItem to="./events">
          <IconItem icon={<i className="las la-home"></i>} minimize={minimize}>
            {tc('events')}
          </IconItem>
        </LinkItem>
        <LinkItem to="./questionnaires">
          <IconItem icon={<i className="las la-home"></i>} minimize={minimize}>
            {t('questionnaires')}
          </IconItem>
        </LinkItem>
        <LinkItem to="./coupons">
          <IconItem icon={<i className="las la-home"></i>} minimize={minimize}>
            {tc('coupons')}
          </IconItem>
        </LinkItem>
        <LinkItem to="./topics">
          <IconItem icon={<i className="las la-home"></i>} minimize={minimize}>
            {tc('topics')}
          </IconItem>
        </LinkItem>
        <LinkItem to="./faqs">
          <IconItem icon={<i className="las la-home"></i>} minimize={minimize}>
            {t('faqs')}
          </IconItem>
        </LinkItem>
        <LinkItem to="./campaigns">
          <IconItem icon={<i className="las la-home"></i>} minimize={minimize}>
            {tc('campaigns')}
          </IconItem>
        </LinkItem>
        <LinkItem to="./location">
          <IconItem icon={<i className="las la-home"></i>} minimize={minimize}>
            {locationName}
          </IconItem>
        </LinkItem>
      </NavigationMenu.List>
    </NavigationMenu.Root>;
}
_s(AppNavigationMenu, "d+lsQPsXgJkEA77kk/KEdzB0iDk=", false, function () {
  return [useTranslation, useTranslation];
});
_c = AppNavigationMenu;
function IconItem({
  icon,
  minimize,
  children
}) {
  return <>
      {icon}
      <span className={clsx({
      'visually-hidden': minimize
    })}>{children}</span>
    </>;
}
_c2 = IconItem;
var _c, _c2;
$RefreshReg$(_c, "AppNavigationMenu");
$RefreshReg$(_c2, "IconItem");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;