// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/$location._c/AppSideBar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/$location._c/AppSideBar.tsx");
}
// REMIX HMR END

import { useState } from 'react';
import { AppNavigationMenu } from './AppNavigationMenu';
import cn from './AppSideBar.module.css';
export function AppSideBar({
  locationName
}) {
  _s();
  const [minimize, setMinimize] = useState(false);
  return <div className={cn.AppSideBar}>
      <button type="button" className={cn.MinimizeButton} onClick={() => setMinimize(v => !v)}>

        {minimize ? <i className="las la-arrow-right"></i> : <i className="las la-arrow-left"></i>}

      </button>
      <AppNavigationMenu locationName={locationName} minimize={minimize} />
    </div>;
}
_s(AppSideBar, "V5dHuqC26iWmRK68DD+IYia7dMA=");
_c = AppSideBar;
var _c;
$RefreshReg$(_c, "AppSideBar");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;