// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/$location._c/AppHeader.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/$location._c/AppHeader.tsx");
}
// REMIX HMR END

import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { useNavigate } from '@remix-run/react';
import { useCallback } from 'react';
import cn from './AppHeader.module.css';
import { AppNavigationDrawer } from './AppNavigationDrawer';
import { LinkItem } from './LinkItem';
export function AppHeader({
  staff,
  locationName,
  heading,
  leaf
}) {
  _s();
  const navigate = useNavigate();
  const handleBack = useCallback(() => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('./');
    }
  }, [navigate]);
  return <NavigationMenu.Root asChild>
      <header className={cn.AppHeader}>
        <div className={cn.Heading}>
          {leaf ? <button onClick={handleBack}>
              <i className="las la-arrow-left"></i>
            </button> : <AppNavigationDrawer locationName={locationName} />}

          <h1>{heading}</h1>
        </div>

        <NavigationMenu.List className={cn.HeaderItem}>
          <LinkItem to="./notifications">
            <i className="las la-bell"></i>
          </LinkItem>

          <NavigationMenu.Item className={cn.HeaderMenu}>
            <NavigationMenu.Trigger>{staff.name}</NavigationMenu.Trigger>
            <NavigationMenu.Content>
              <NavigationMenu.List>
                <LinkItem to="./settings">settings</LinkItem>
              </NavigationMenu.List>
            </NavigationMenu.Content>
            <NavigationMenu.Viewport className={cn.HeaderMenuViewPort} />
          </NavigationMenu.Item>
        </NavigationMenu.List>
      </header>
    </NavigationMenu.Root>;
}
_s(AppHeader, "akYAZvWrvpeRwZ65Xy1zdD1Qo7s=", false, function () {
  return [useNavigate];
});
_c = AppHeader;
var _c;
$RefreshReg$(_c, "AppHeader");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;