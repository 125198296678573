// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/$location._c/route.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/$location._c/route.tsx");
  import.meta.hot.lastModified = "1693363611602.5215";
}
// REMIX HMR END

import { locationExample } from '@commural/shared-resources/schemas/business/Location.example';
import { staffListExample } from '@commural/shared-resources/schemas/business/Staff.example';
import { capitalize } from '@commural/shared-resources/utils/common';
import { Outlet, useLoaderData, useMatches } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { LocationContainer } from './LocationContainer';
export function loader({
  params
}) {
  invariant(params.location, 'location is required');
  const location = locationExample;
  const staff = staffListExample[0];
  return {
    location,
    staff
  };
}
export default function Index() {
  _s();
  const {
    location,
    staff
  } = useLoaderData();
  const matches = useMatches();
  const leaf = matches.some(match => match.handle?.leaf);
  const titleKey = matches.reduce((prev, curr) => curr?.handle?.title ?? prev, 'commural');
  const {
    t,
    i18n: {
      language
    }
  } = useTranslation();
  const title = capitalize(t(titleKey), language);
  return <LocationContainer staff={staff} heading={title} locationName={location.name} leaf={leaf}>
      <Outlet />
    </LocationContainer>;
}
_s(Index, "x8X/MJ4NVMtKIo3luozc989xJYc=", false, function () {
  return [useLoaderData, useMatches, useTranslation];
});
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;