// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/$location._c/LocationContainer.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/$location._c/LocationContainer.tsx");
}
// REMIX HMR END

import { AppHeader } from './AppHeader';
import { AppSideBar } from './AppSideBar';
import cn from './LocationContainer.module.css';
export function LocationContainer({
  staff,
  locationName,
  heading,
  leaf,
  children
}) {
  return <div className={cn.LocationContainer}>
      <AppSideBar locationName={locationName} />
      <main>
        <AppHeader staff={staff} locationName={locationName} heading={heading} leaf={leaf} />

        {children}
      </main>
    </div>;
}
_c = LocationContainer;
var _c;
$RefreshReg$(_c, "LocationContainer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;