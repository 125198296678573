// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/$location._c/LinkItem.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/$location._c/LinkItem.tsx");
}
// REMIX HMR END

import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { Link as BaseLink, useHref, useLocation } from '@remix-run/react';
import { forwardRef } from 'react';
import cn from './LinkItem.module.css';
export function LinkItem({
  to,
  children
}) {
  return <NavigationMenu.Item>
      <Link to={to}>{children}</Link>
    </NavigationMenu.Item>;
}
_c = LinkItem;
const Link = _s(forwardRef(_c2 = _s(({
  to,
  ...props
}, forwardedRef) => {
  _s();
  const location = useLocation();
  const href = useHref(to);
  return <NavigationMenu.Link asChild active={href === location.pathname}>
      <BaseLink to={to} {...props} ref={forwardedRef} className={cn.Link} />
    </NavigationMenu.Link>;
}, "X54+skCFfWRKF04gmneclJE7EGE=", false, function () {
  return [useLocation, useHref];
})), "X54+skCFfWRKF04gmneclJE7EGE=", false, function () {
  return [useLocation, useHref];
});
_c3 = Link;
var _c, _c2, _c3;
$RefreshReg$(_c, "LinkItem");
$RefreshReg$(_c2, "Link$forwardRef");
$RefreshReg$(_c3, "Link");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;