import { z } from 'zod';
import { Staff } from './Staff';

export const staffListExample = z.array(Staff).parse([
  ...Array.from({ length: 50 }, (_, i) => ({
    id: `staff-${i}`,
    locationId: 'location-0',
    name: `スタッフ ${i}`,
    role: 'staff',
    lastLoginAt: new Date(Date.UTC(2021, 1, 1 + i, 0, 0, 0, 0)).toISOString(),
    createdAt: new Date(Date.UTC(2021, 1, 1 + i, 0, 0, 0, 0)).toISOString(),
    updatedAt: new Date(Date.UTC(2021, 1, 1 + i, 0, 0, 0, 0)).toISOString(),
  })),
]);

staffListExample[0].role = 'owner';
staffListExample[1].role = 'admin';
