// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/$location._c/AppNavigationDrawer.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/$location._c/AppNavigationDrawer.tsx");
}
// REMIX HMR END

import { useDialog } from '~/components/useDialog';
import cn from './AppNavigationDrawer.module.css';
import { AppNavigationMenu } from './AppNavigationMenu';
export function AppNavigationDrawer({
  locationName
}) {
  _s();
  const {
    open,
    close,
    Dialog
  } = useDialog();
  return <div className={cn.NavigationDrawer}>
      <button type="button" onClick={open}>
        <i className="las la-bars"></i>
      </button>
      <Dialog className={cn.NavigationDrawerDialog}>
        <section>
          <button type="button" onClick={close}>
            <i className="las la-times"></i>
          </button>
          <AppNavigationMenu locationName={locationName} />
        </section>
      </Dialog>
    </div>;
}
_s(AppNavigationDrawer, "+NPpGC+DVYD73mHbU65oOUIEJ8A=", false, function () {
  return [useDialog];
});
_c = AppNavigationDrawer;
var _c;
$RefreshReg$(_c, "AppNavigationDrawer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;